import { GetShopItems_getShopItems_lootRewardPackage_lootRewards as lootReward } from '@g4g/graphql/src/shop/__generated__/GetShopItems'
import { VIP_LEVEL_CATEGORY } from '@g4g/utils/src/constants/gameConstants'
import { RewardType } from '@g4g/graphql/src/shop/__generated__/globalTypes'

export interface ILootRewardProps {
  index?: number
  loot: lootReward
  rewardParentClass?: string
  vipLevelCategory?: VIP_LEVEL_CATEGORY
  animateRewardIn?: boolean
}

export const getAnimationDelay = (ms: number): React.CSSProperties => {
  return { '--animation-delay': `${ms}ms` } as React.CSSProperties
}

export const isVipExp = (loot: lootReward) => loot.rewardType === RewardType.VipExp
