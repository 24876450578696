import type { FC } from 'react'
import type { Price } from '../shopping-cart/hooks/useCalculatedPrices'
import PrpsDubiAmount, { IPrpsDubiAmountProps } from './PrpsDubiAmount'
import { classNames } from '@g4g/utils/src/react/class-names'

const DUBI_DISCOUNT_MULTIPLIER = 1

const PrpsDubiPrice: FC<
  { prices: Price; crossFade?: boolean } & Omit<
    IPrpsDubiAmountProps,
    'amount' | 'dubiAmount' | 'originalAmount'
  >
> = ({ prices, crossFade, ...props }) => {
  const dubiDiscount = props.discount ? props.discount * DUBI_DISCOUNT_MULTIPLIER : props.discount
  const prpsDiscountColor =
    dubiDiscount && props.discount && dubiDiscount > props.discount ? 'blood' : 'red'
  const hasOriginalPrice = prices.price !== prices.originalPrice

  return (
    <div className="relative flex flex-wrap">
      <PrpsDubiAmount
        {...props}
        discountColor={prpsDiscountColor}
        parentClassName={classNames(
          props.parentClassName,
          crossFade ? 'animate-cross-fade' : 'pr-2'
        )}
        tokenSymbol="PRPS"
        amount={prices.price}
        originalAmount={hasOriginalPrice ? prices.originalPrice : prices.price}
      />
      <PrpsDubiAmount
        {...props}
        discount={dubiDiscount}
        parentClassName={classNames(
          props.parentClassName,
          crossFade && 'absolute left-0 top-0 animate-cross-fade-reverse'
        )}
        tokenSymbol="DUBI"
        amount={prices.dubiPrice}
        originalAmount={hasOriginalPrice ? prices.originalPrice : prices.dubiPrice}
      />
    </div>
  )
}

export default PrpsDubiPrice
