import { FC, useState } from 'react'
import { useLootShine, useLootShineCandidates } from './LootShineProvider'
import type { RewardType } from '@g4g/graphql/src/shop/__generated__/globalTypes'
import { useUpdateEffect } from 'react-use'
import { classNames } from '@g4g/utils/src/react/class-names'

const LootShineTransition: FC = () => {
  const { ids } = useLootShineCandidates()
  const winner = useLootShine()[0]
  // Winner triggers a rerender so the ids
  // get shuffled on every tick
  const [id, setId] = useState(ids[Math.floor(Math.random() * ids.length)])

  useUpdateEffect(() => {
    setId(ids[Math.floor(Math.random() * ids.length)])
  }, [winner])

  return (
    <div
      style={
        id === winner
          ? { transform: 'translate3d(100%, 0%, 1px)' }
          : { transform: 'translate3d(-100%, -200%, 1px)', opacity: 0 }
      }
      className={classNames(
        'w-full h-full blur-xl transition-transform',
        'duration-[2500ms] opacity-100 rounded-lg to-transparent',
        'bg-gradient-to-br from-transparent via-white/80'
      )}
    ></div>
  )
}

const LootShine: FC<{ rewardType: RewardType }> = ({ rewardType }) => {
  const { shinyRewards } = useLootShineCandidates()

  return <>{shinyRewards.includes(rewardType) && <LootShineTransition />}</>
}

export default LootShine
