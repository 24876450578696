import React from 'react'
import Head from 'next/head'
import { CoSApps, descriptions } from '@g4g/utils/src/seo/meta'

interface IMetaProps {
  title: string
  description?: string
  keywords?: string
  canonical?: string

  socialMediaImage?: string
  socialMediaImageAlt?: string

  noIndex?: boolean
  app?: CoSApps
}

const Meta: React.FC<IMetaProps> = (props) => {
  return (
    <Head>
      <meta charSet="utf-8" />
      <title>{props.title}</title>

      <meta name="google-site-verification" content="4rRWk2y2A2mF7JRNW6uwHJKdQ9Z-eofciV5048f1p10" />
      <meta name="description" content={props.app ? descriptions[props.app] : props.description} />
      <meta
        name="keywords"
        content={
          props.keywords ??
          'Athene AI Heroes, Mobile Minigames, Mobile Minigames Android, Mobile Minigames iOS, Mobile Minigames Download, Mobile Minigames NFTs, NFT Game, Cryptocurrency Game, Idle Game, Streaming Game, Offline Game, Blockchain Game'
        }
      />
      <meta
        name="theme-color"
        content={props.app === 'shop' || props.app === 'login' ? '#212529' : '#fa8203'}
      />
      {(props.app === 'shop' || props.app === 'login') && (
        <meta name="color-scheme" content="dark" />
      )}

      <meta name="twitter:title" content={props.title} />
      <meta
        name="twitter:description"
        content={props.app ? descriptions[props.app] : props.description}
      />
      <meta name="twitter:site" content="@@ClashOfStreamer" />
      <meta name="twitter:creator" content="@@ClashOfStreamer" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={props.socialMediaImage ?? ''} />
      <meta name="twitter:image:width" content="480" />
      <meta name="twitter:image:height" content="270" />
      <meta property="twitter:image:alt" content={props.socialMediaImageAlt ?? ''} />

      <meta property="og:title" content={props.title} />
      <meta
        property="og:description"
        content={props.app ? descriptions[props.app] : props.description}
      />
      <meta property="og:url" content={props.canonical} />
      <meta property="og:site_name" content="Mobile Minigames" />
      <meta property="og:image" content={props.socialMediaImage ?? ''} />
      <meta property="og:image:secure_url" content={props.socialMediaImage ?? ''} />
      <meta property="og:image:width" content="480" />
      <meta property="og:image:height" content="270" />
      <meta property="og:image:alt" content={props.socialMediaImageAlt ?? ''} />

      {props.noIndex ? <meta name="robots" content="noindex" /> : <></>}
      {props.canonical ? <link rel="canonical" href={props.canonical} /> : <></>}

      <link rel="shortcut icon" href="/favicon.ico" />

      <link
        href="/shared-assets/fonts/PoetsenOne-Regular.ttf"
        as="font"
        type="font/ttf"
        crossOrigin="anonymous"
      />
      <link
        href="/shared-assets/fonts/ccyoublockhead.otf"
        as="font"
        type="font/otf"
        crossOrigin="anonymous"
      />
    </Head>
  )
}

export default Meta
