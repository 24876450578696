import { FC, createContext, useContext, ReactNode } from 'react'
import { createStateContext } from 'react-use'
import useRandomInterval from '@g4g/utils/src/react/useRandomInterval'
import { RewardType } from '@g4g/graphql/src/shop/__generated__/globalTypes'

const candidates = {
  // Only these rewards are shiny
  shinyRewards: [
    RewardType.Hero,
    RewardType.Crypton,
    RewardType.SalePoints,
    RewardType.KappaScroll,
    RewardType.PogChampScroll,
    RewardType.MegaChip,
    RewardType.MadnessChip,
    RewardType.InsanityChip,
    RewardType.AscendanceChip,
    RewardType.TTTTVoucher,
  ],
  // Ids to assign to the <LootShine /> elements
  ids: [] as number[],
}
// The transform transition duration
const LOOTSHINE_DURATION = 2500
// Max amount of time to wait between triggering
// the next shine
const MAX_DELAY_BETWEEN_NEXT_SHINE = 5000
// A non-reactive context to hold a list of possible ids
// to get picked from, every <LootShine /> will get
// an id assigned from this set
const LootShineCandidates = createContext(candidates)
const useLootShineCandidates = () => useContext(LootShineCandidates)
// Holds the current id of <LootShine /> to animate,
// can be multiple at once since the same id can be assigned
// to multiple different <Lootshine /> elements
const [useLootShine, StateProvider] = createStateContext(-1)

const ShineLottery: FC<{ ids: number[]; children?: ReactNode }> = ({ children, ids }) => {
  const setWinner = useLootShine()[1]

  // Tick every [LOOTSHINE_DURATION, MAX_DELAY] ms
  useRandomInterval(
    () => {
      // Pick a random id on every tick
      const winner = ids[Math.floor(Math.random() * ids.length)]
      setWinner(winner)
    },
    LOOTSHINE_DURATION,
    MAX_DELAY_BETWEEN_NEXT_SHINE
  )

  return <>{children}</>
}

const LootShineProvider: FC<{ magicNumber: number; children?: ReactNode }> = ({
  children,
  magicNumber,
}) => {
  // Decreasing the magicNumber value will reduce the pool of ids to pick
  // from, different pages have different number of loot rewards displayed
  // so it basically increases/decreases the frequency of the shine effects
  const ids = Array.from({ length: magicNumber }, () => Math.floor(Math.random() * magicNumber))

  return (
    <LootShineCandidates.Provider value={{ shinyRewards: candidates.shinyRewards, ids }}>
      <StateProvider>
        <ShineLottery ids={ids}>{children}</ShineLottery>
      </StateProvider>
    </LootShineCandidates.Provider>
  )
}

export { useLootShine, useLootShineCandidates }
export default LootShineProvider
