export type CoSApps = 'shop' | 'burn' | 'cos-com' | 'login'

const cosComDescription =
  'Full livestreaming capabilities. Innovative blockchain integration. Full social media integration. Fully offline play. Mobile Minigames is your personal peek into the future of gaming.'

export const descriptions: { [k in CoSApps]: string } = {
  shop: 'Purchase insane deals in the Mobile Minigames Shop with PRPS or DUBI for huge in-game rewards.',
  'cos-com': cosComDescription,
  login: cosComDescription,
  burn: 'Burn Mobile Minigames hero and pet NFTs and earn the cryptocurrency PRPS (Purpose). The longer you hold, the more PRPS you earn!',
} as const
