import type { FC } from 'react'
import Image from 'next/image'
import { TimeInterval } from '@g4g/graphql/src/shop/__generated__/globalTypes'
import lootRibbonImg from '@/shop/public/shared-assets/img/lootribbon.png'
import { classNames } from '@g4g/utils/src/react/class-names'

const label: { [k in TimeInterval]: string } = {
  Day: 'DAILY',
  Week: 'WEEKLY',
  Month: 'MONTHLY',
  Year: 'YEARLY',
} as const

const LootTimeIntervalRibbon: FC<{
  timeInterval: TimeInterval
}> = ({ timeInterval }) => (
  <div data-cy="shop-loot-time-ribbon" className="absolute top-0 right-0">
    <div className="relative flex justify-end w-full h-11 pr-[2px]">
      <Image src={lootRibbonImg} width={48} height={42} alt="" />
      <div className="absolute top-0 right-0">
        <span
          className={classNames(
            timeInterval === TimeInterval.Day && '-translate-x-1',
            timeInterval === TimeInterval.Year && '-translate-x-0.5',
            'flex w-full h-full text-white text-xxs',
            'rotate-[42deg] translate-y-1.5 font-poet [text-shadow:0_0px_2px_rgba(0,0,0)]'
          )}
        >
          {label[timeInterval]}
        </span>
      </div>
    </div>
  </div>
)

export default LootTimeIntervalRibbon
