import type { FC } from 'react'
import { simplifyCosRewardAmount } from '@g4g/utils/src/numberUtils'
import { LootNotation, RewardType } from '@g4g/graphql/src/shop/__generated__/globalTypes'
import { VIP_LEVEL_CATEGORY } from '@g4g/utils/src/constants/gameConstants'
import VipText from '../VipText'
import { classNames } from '@g4g/utils/src/react/class-names'

interface ILootRewardTextProps {
  className: string
  rewardType: RewardType
  notation: LootNotation
  amount: number
  vipLevelCategory?: VIP_LEVEL_CATEGORY
  responsive?: boolean
}

const amountSymbol = (rewardType: RewardType, notation: LootNotation, prefix: boolean): string => {
  if (prefix && (rewardType === RewardType.SalePoints || rewardType === RewardType.VipExp)) {
    return '+'
  }

  if (prefix && notation === LootNotation.Multiplier) {
    return 'x'
  }

  if (!prefix && notation === LootNotation.Percentage) {
    return '%'
  }

  return ''
}

const formatAmount = (amount: string, rewardType: RewardType, notation: LootNotation) =>
  `${amountSymbol(rewardType, notation, true)}${amount}${amountSymbol(rewardType, notation, false)}`

const LootRewardText: FC<ILootRewardTextProps> = (props) => {
  const amount = formatAmount(
    simplifyCosRewardAmount(props.amount),
    props.rewardType,
    props.notation
  )

  return (
    <>
      {props.rewardType === RewardType.VipExp ? (
        <VipText
          className={classNames(
            props.className,
            props.responsive ? 'text-xl lg:text-2xl' : 'text-xl'
          )}
          vipCategory={props.vipLevelCategory ?? 1}
        >
          {amount}
        </VipText>
      ) : (
        <p
          className={classNames(
            'font-poet',
            props.responsive ? 'text-xl lg:text-2xl' : 'text-xl',
            props.className,
            props.rewardType === RewardType.SalePoints
              ? 'text-red-salepoints cos-salepoints-text-shadow'
              : 'text-white cos-standard-text-shadow'
          )}
          data-cy="shop-loot-reward-text"
        >
          {amount}
        </p>
      )}
    </>
  )
}

export default LootRewardText
