import type { FC } from 'react'
import { classNames } from '@g4g/utils/src/react/class-names'
import TokenLogo from './wallet/TokenLogo'
import type { ERC20Symbols } from '@g4g/wallet-modal/types'

export interface IPrpsDubiAmountProps {
  amount: string
  originalAmount?: string
  textClassSize?: string
  discount?: number | null
  showDiscountPercent?: boolean
  discountColor?: 'red' | 'blood'
  discountPercentClass?: string
  parentClassName?: string
  amountColorClass?: string
  tokenLogoClass?: string
  tokenSymbol?: ERC20Symbols
}

const PrpsDubiAmount: FC<IPrpsDubiAmountProps> = (props) => (
  <div className={classNames(props.parentClassName, 'flex items-center')}>
    <TokenLogo symbol={props.tokenSymbol ?? 'PRPS'} sizeClassName={props.tokenLogoClass} />
    <div className="flex flex-col ml-1.5 text-left">
      {!!props.originalAmount && props.originalAmount !== props.amount && (
        <div
          data-cy="shop-dollar-amount-original"
          className="line-through text-xxs text-white/60 font-poppins !leading-none"
        >
          {props.originalAmount}
        </div>
      )}
      {!!props.amount && (
        <div
          data-cy="shop-dollar-amount-current"
          className={classNames(
            props.amountColorClass ? props.amountColorClass : 'text-green-400',
            'font-poppins font-medium !leading-none',
            props.textClassSize
          )}
        >
          {props.amount}
        </div>
      )}
    </div>
  </div>
)

export default PrpsDubiAmount
