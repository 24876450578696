import type { FC, ReactNode } from 'react'
import { VIP_LEVEL_CATEGORY } from '@g4g/utils/src/constants/gameConstants'

const VipText: FC<{
  vipCategory: VIP_LEVEL_CATEGORY
  className: string
  style?: React.CSSProperties
  children?: ReactNode
}> = ({ vipCategory, className, style, children }) => {
  const vipTextColor =
    vipCategory === 1
      ? 'text-vipCategory1'
      : vipCategory === 2
      ? 'text-vipCategory2'
      : vipCategory === 3
      ? 'text-vipCategory3'
      : vipCategory === 4
      ? 'text-vipCategory4'
      : 'text-vipCategory5'

  return (
    <p
      data-cy="shop-loot-reward-text"
      className={`font-poet cos-standard-text-shadow ${className} ${vipTextColor}`}
      style={style}
    >
      {children}
    </p>
  )
}

export default VipText
