import type { FC } from 'react'
import type { GetShopItems_getShopItems_lootRewardPackage_lootRewards_hero } from '@g4g/graphql/src/shop/__generated__/GetShopItems'
import type {
  HeroClass as HeroClasses,
  Faction,
} from '@g4g/graphql/src/shop/__generated__/globalTypes'
import { StaticImageData } from 'next/image'
import Image from 'next/image'
import { classNames } from '@g4g/utils/src/react/class-names'
import LgBreakpoint from '@g4g/ui/src/utility/LgBreakpoint'
import lowLvlPlateImg from '@/shop/public/shared-assets/img/nfts/low_level_plate.png'
import midLvlPlateImg from '@/shop/public/shared-assets/img/nfts/mid_level_plate.png'
import highLvlPlateImg from '@/shop/public/shared-assets/img/nfts/high_level_plate.png'

const MAX_STARS_IN_A_TIER = 4

const tier = (stat: number) => (stat < 5 ? 'low' : stat < 9 ? 'mid' : 'high')

const starsCount = (stars: number) =>
  stars % MAX_STARS_IN_A_TIER === 0 ? MAX_STARS_IN_A_TIER : stars % MAX_STARS_IN_A_TIER

const scaleImageSize = (size: number, large?: boolean) => (large ? Math.ceil(1.15 * size) : size)

const plates: { [k in 'low' | 'mid' | 'high']: StaticImageData } = {
  low: lowLvlPlateImg,
  mid: midLvlPlateImg,
  high: highLvlPlateImg,
} as const

const HeroStars: FC<{ stars: number; large?: boolean }> = ({ stars, large }) => (
  <div
    className={classNames(
      'flex justify-center order-2 w-full h-full',
      large ? '-translate-y-5' : '-translate-y-5'
    )}
  >
    {[...Array(starsCount(stars))].map((_, i) => (
      <div
        key={i}
        className={classNames(
          'flex  justify-center items-center ',
          large ? 'w-[18px] h-[18px] -m-[4px]' : 'w-[15px] h-[15px] -m-[2.5px]'
        )}
      >
        <Image
          src={`/shared-assets/img/hero/stars/${tier(stars)}_star.png`}
          alt={`Hero stars ${stars}`}
          width={scaleImageSize(15, large)}
          height={scaleImageSize(15, large)}
        />
      </div>
    ))}
  </div>
)

const HeroFaction: FC<{ faction: Faction; large?: boolean }> = ({ faction, large }) => (
  <div
    className={classNames(
      'relative flex items-center justify-center',
      large ? 'w-[33px] h-[33px]' : 'w-7 h-7'
    )}
  >
    <Image
      className="rounded-full"
      src={`/shared-assets/img/hero/faction/${faction.toLowerCase()}.jpg`}
      alt={faction}
      width={scaleImageSize(28, large)}
      height={scaleImageSize(28, large)}
    />
  </div>
)

const HeroLevel: FC<{ level: number; large?: boolean }> = ({ level, large }) => {
  const levelTier = tier(level)
  return (
    <div className="relative flex items-center justify-center translate-x-0.5 -translate-y-0.5 z-1">
      <Image
        src={plates[levelTier]}
        width={scaleImageSize(48, large)}
        height={scaleImageSize(24, large)}
        alt={`Hero level ${level}`}
      />
      <div className="absolute flex items-center justify-center w-full h-full">
        <span
          className={classNames(
            'font-poet text-transparent bg-clip-text bg-gradient-to-t',
            levelTier === 'low' && 'from-[#5cbae7] to-[#96edf2]',
            levelTier === 'mid' && 'from-[#8e6de6] to-[#cd88fe]',
            levelTier === 'high' && 'from-[#d73b0d] to-[#fb7b17]'
          )}
        >
          {level === 12 ? 'Max' : `LV.${level}`}
        </span>
      </div>
    </div>
  )
}

const HeroClass: FC<{ heroClass: HeroClasses; large?: boolean }> = ({ heroClass, large }) => (
  <div
    className={classNames(
      'relative flex items-center justify-center',
      large ? 'w-14 h-14 -translate-x-4' : 'w-12 h-12 -translate-x-3 -translate-y-0.5'
    )}
  >
    <Image
      src={`/shared-assets/img/hero/classes/${heroClass.toLowerCase()}.png`}
      width={scaleImageSize(26, large)}
      height={scaleImageSize(50, large)}
      alt={heroClass}
    />
  </div>
)

const HeroFace: FC<{ level: number; large?: boolean }> = ({ level, large }) => (
  <div
    className={classNames(
      'relative flex items-center justify-center translate-x-0.5',
      large ? '-translate-y-[15px]' : '-translate-y-[10px]'
    )}
  >
    <Image
      src={`/shared-assets/img/hero/faces/${level}-star.png`}
      width={scaleImageSize(100, large)}
      height={scaleImageSize(100, large)}
      alt="Hero Face"
    />
  </div>
)

const HeroLootRewardBody: FC<{
  hero: GetShopItems_getShopItems_lootRewardPackage_lootRewards_hero
  large?: boolean
}> = ({ hero, large }) => (
  <div className="relative flex flex-col w-full h-full overflow-hidden">
    {/* Order is needed so that the Face stays under the Level */}
    <div className="flex order-1">
      <HeroFace level={hero.level} large={large} />
      <HeroClass heroClass={hero.class} large={large} />
    </div>
    <div className="flex justify-between w-full order-0">
      <HeroLevel level={hero.level} large={large} />
      <HeroFaction faction={hero.faction} large={large} />
    </div>
    <HeroStars stars={hero.stars} large={large} />
  </div>
)

const HeroLootReward: FC<{
  hero: GetShopItems_getShopItems_lootRewardPackage_lootRewards_hero
  responsive?: boolean
}> = ({ hero, responsive }) => (
  <>
    {responsive ? (
      <LgBreakpoint
        min={<HeroLootRewardBody hero={hero} large />}
        max={<HeroLootRewardBody hero={hero} />}
      />
    ) : (
      <HeroLootRewardBody hero={hero} />
    )}
  </>
)

export default HeroLootReward
