import type { FC } from 'react'
import { StaticImageData } from 'next/image'
import Image from 'next/image'
// Top deal loot reward containers
import salePointsFreeDealImg from '@/shop/public/shared-assets/img/resources/salepoints_free_deal.png'
import salepointsContainerImg from '@/shop/public/shared-assets/img/resources/salepoints_container.png'
import heroContainer from '@/shop/public/shared-assets/img/resources/hero_container.png'
import vipContainer1Img from '@/shop/public/shared-assets/img/resources/vip_container_1.png'
import vipContainer3Img from '@/shop/public/shared-assets/img/resources/vip_container_3.png'
import { classNames } from '@g4g/utils/src/react/class-names'
import { isSkinLootImg } from '@g4g/utils/src/gameDataUtils'

const containers: { [k in string]: StaticImageData } = {
  salepoints_free_deal: salePointsFreeDealImg,
  salepoints_container: salepointsContainerImg,
  hero_container: heroContainer,
  vip_container_1: vipContainer1Img,
  vip_container_3: vipContainer3Img,
} as const

// default standard_container blurData hardcoded
// so it can be used for all the containers that are
// not imported above
const standardBlurData =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAMAAADz0U65AAAAQlBMVEXt0rnauqPo0Lrox6/EkH2BUkN5SjzNinq+gHHKoYzju6XTloW7em3ap5SBUkPdq5fQjH3QrpiCVEWoal2pal3ctp/r+T2RAAAADXRSTlP+w8P//vzK/v79///+trItjQAAAAlwSFlzAAAATwAAAE8BY4r91wAAADpJREFUCJkdwQsSgCAIQMFXfoACNev+Z22GXQ6AwomV9GBXMuqMiJgVvZPiS0RkOa+6u+tH322MtvsPQYACPpi7lVkAAAAASUVORK5CYII='

const resolveCommonProps = (name: string, isContainer?: boolean, size?: number) => {
  if (isSkinLootImg(name)) {
    return { src: name, fill: true }
  }

  const isImportedContainer = Object.prototype.hasOwnProperty.call(containers, name)

  return {
    // Top deals have the container imported for a more
    // accurate blur effect
    src: isImportedContainer ? containers[name] : `/shared-assets/img/resources/${name}.png`,
    width: size,
    height: size,
    // Always blur the container
    ...(isContainer ? { placeholder: 'blur' } : {}),
    // Use a standard blur for all the non imported containers
    ...(isContainer && !isImportedContainer ? { blurDataURL: standardBlurData } : {}),
  } as {
    src: string | StaticImageData
    placeholder?: 'blur'
    blurDataURL?: string
  }
}

const StandardLootRewardImage: FC<{
  name?: string
  isContainer?: boolean
  size?: number
  upperSize?: string
  alt?: string
  classNames?: string
}> = ({ size = 80, classNames: c, name, alt = '', upperSize, isContainer }) => (
  <Image
    {...resolveCommonProps(name ?? '', isContainer, size)}
    className={classNames(c, 'object-contain')}
    sizes={upperSize}
    alt={alt}
  />
)

export default StandardLootRewardImage
