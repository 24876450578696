import type { FC, ReactNode } from 'react'
import { classNames as c } from '@g4g/utils/src/react/class-names'

const Badge: FC<{
  color?: 'red' | 'blood' | 'green' | 'purple' | 'blue' | 'gray'
  skew?: boolean
  wrapperClassNames?: string
  classNames?: string
  children?: ReactNode
  icon?: ReactNode
  'data-cy'?: string
}> = ({
  children,
  icon,
  skew,
  wrapperClassNames,
  color = 'red',
  'data-cy': dataCy,
  classNames,
}) => (
  <div className={wrapperClassNames}>
    <div
      className={c(
        classNames,
        'flex shadow-lg items-center space-x-2 text-white relative font-bold',
        skew
          ? 'pl-2 pr-4 rounded-l rounded-br after:-skew-x-[20deg] after:absolute after:w-4 after:h-full after:-right-1.5 after:top-0 after:rounded'
          : 'rounded',
        color === 'red' && 'bg-red-600',
        color === 'blood' && 'bg-red-800',
        color === 'green' && 'bg-green-700',
        color === 'purple' && 'bg-purple-700',
        color === 'blue' && 'bg-blue-700',
        color === 'gray' && 'bg-gray-700',
        skew && color === 'red' && ' after:bg-red-600',
        skew && color === 'blood' && ' after:bg-red-800',
        skew && color === 'green' && 'after:bg-green-700',
        skew && color === 'purple' && ' after:bg-purple-700',
        skew && color === 'blue' && 'after:bg-blue-700',
        skew && color === 'gray' && 'after:bg-gray-700'
      )}
    >
      <>{icon}</>
      <span data-cy={dataCy}>{children}</span>
    </div>
  </div>
)

export default Badge
