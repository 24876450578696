import { useEffect, useRef, useCallback } from 'react'
import { randomFromRange } from '../numberUtils'

/**
 * Same as interval but ticking at every [min...max] ms
 *
 * @see https://www.joshwcomeau.com/snippets/react-hooks/use-random-interval/
 */
const useRandomInterval = (callback: Function, minDelay: number, maxDelay: number) => {
  const timeoutId = useRef<number | undefined>(undefined)
  const savedCallback = useRef(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    let isEnabled = typeof minDelay === 'number' && typeof maxDelay === 'number'
    if (isEnabled) {
      const handleTick = () => {
        const nextTickAt = randomFromRange(minDelay, maxDelay)
        timeoutId.current = window.setTimeout(() => {
          savedCallback.current()
          handleTick()
        }, nextTickAt)
      }
      handleTick()
    }
    return () => window.clearTimeout(timeoutId.current)
  }, [minDelay, maxDelay])

  const cancel = useCallback(function () {
    window.clearTimeout(timeoutId.current)
  }, [])

  return cancel
}

export default useRandomInterval
