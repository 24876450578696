import type { FC } from 'react'
import type { ERC20Symbols } from '@g4g/wallet-modal/types'
import { StaticImageData } from 'next/image'
import Image from 'next/image'
import prpsLogo from '@/shop/public/shared-assets/img/prps.png'
import dubiLogo from '@/shop/public/shared-assets/img/dubi.png'
import { classNames } from '@g4g/utils/src/react/class-names'

const logos: { [k in ERC20Symbols]: StaticImageData } = {
  PRPS: prpsLogo,
  DUBI: dubiLogo,
} as const

const TokenLogo: FC<{ symbol: ERC20Symbols; sizeClassName?: string }> = ({
  symbol,
  sizeClassName = 'w-8 h-8',
}) => (
  <div className={classNames('relative', sizeClassName)}>
    <Image height={48} width={48} sizes="64px" src={logos[symbol]} alt={`${symbol} Logo`} />
  </div>
)

export default TokenLogo
