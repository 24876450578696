import type { FC } from 'react'
import { RewardType } from '@g4g/graphql/src/shop/__generated__/globalTypes'
import {
  getVipCategory,
  lootRewardContainer,
  lootRewardIcon,
  isSalePoints,
  isFreeDeal,
} from '@g4g/utils/src/gameDataUtils'
import { getAnimationDelay } from './lootRewards'
import LootRewardText from './LootRewardText'
import LootShine from './LootShine'
import { classNames } from '@g4g/utils/src/react/class-names'
import type { GetShopItems_getShopItems_lootRewardPackage_lootRewards } from '@g4g/graphql/src/shop/__generated__/GetShopItems'
import type { VIP_LEVEL_CATEGORY } from '@g4g/utils/src/constants/gameConstants'
import HeroLootReward from './HeroLootReward'
import LootTimeIntervalRibbon from './LootTimeIntervalRibbon'
import StandardLootRewardImage from './StandardLootRewardImage'

const LootRewardIcon: FC<{
  loot: GetShopItems_getShopItems_lootRewardPackage_lootRewards
  index?: number
  responsive?: boolean
}> = ({ loot, index, responsive }) => {
  const iconName = lootRewardIcon(loot)
  const c = classNames('w-full h-full', isSalePoints(loot) ? 'scale-95' : 'scale-90 -translate-y-1')

  return (
    <>
      {(iconName || loot.rewardType === RewardType.Hero) && (
        <div className="absolute inset-0" style={getAnimationDelay(Math.floor((index ?? 0) * 100))}>
          {loot.rewardType === RewardType.Hero && loot.hero ? (
            <HeroLootReward hero={loot.hero} responsive={responsive} />
          ) : (
            <div className="relative w-full h-full">
              {responsive ? (
                <StandardLootRewardImage
                  name={iconName}
                  classNames={c}
                  size={96}
                  upperSize="96px"
                  alt={loot.rewardType}
                />
              ) : (
                <StandardLootRewardImage
                  name={iconName}
                  classNames={classNames(c, 'max-w-full h-auto')}
                  alt={loot.rewardType}
                />
              )}
            </div>
          )}
        </div>
      )}
    </>
  )
}

const AmountText: FC<{
  loot: GetShopItems_getShopItems_lootRewardPackage_lootRewards
  vipLevelCategory?: VIP_LEVEL_CATEGORY
  responsive?: boolean
}> = ({ loot, vipLevelCategory, responsive }) => (
  <>
    {!isFreeDeal(loot) && (
      <div className="absolute bottom-0 right-0 top-auto left-auto w-full">
        <div
          className={classNames('relative ml-auto mr-0', isSalePoints(loot) ? 'w-3/4' : 'w-full')}
        >
          <LootRewardText
            className={classNames(
              'm-auto pr-[0.25em]',
              isSalePoints(loot) ? 'text-center pb-[0.5em]' : 'text-right pb-[0.15em]'
            )}
            rewardType={loot.rewardType}
            notation={loot.notation}
            amount={loot.amount}
            responsive={responsive}
            vipLevelCategory={vipLevelCategory ?? getVipCategory({ exp: loot.amount })}
          />
        </div>
      </div>
    )}
  </>
)

const StandardLootReward: FC<{
  loot: GetShopItems_getShopItems_lootRewardPackage_lootRewards
  animateRewardIn?: boolean
  vipLevelCategory?: VIP_LEVEL_CATEGORY
  index?: number
  shine?: boolean
  responsive?: boolean
}> = ({ index, loot, animateRewardIn = true, vipLevelCategory, shine, responsive }) => {
  const containerName = lootRewardContainer(loot)

  return (
    <div className="flex p-0.5 items-center justify-center">
      <div
        data-cy="shop-loot-reward"
        data-cy-spec={`shop-loot-reward-${loot.rewardType.toLowerCase()}${
          loot.timeInterval ? `${loot.timeInterval}` : ''
        }`}
        className={classNames(
          'relative overflow-hidden',
          containerName === 'standard_container' || containerName === 'hero_container'
            ? 'rounded-xl'
            : 'rounded-lg',
          responsive ? 'h-20 w-20 lg:h-24 lg:w-24' : 'h-20 w-20',
          animateRewardIn && 'animateIn'
        )}
        style={getAnimationDelay(Math.floor((index ?? 0) * 75))}
      >
        {responsive ? (
          <StandardLootRewardImage
            name={containerName}
            isContainer
            size={96}
            upperSize="96px"
            alt={loot.rewardType}
          />
        ) : (
          <StandardLootRewardImage
            classNames={'max-w-full h-auto'}
            isContainer
            name={containerName}
            alt={loot.rewardType}
          />
        )}
        {/* Vip exp has the reward icon already in the background image. */}
        {loot.rewardType !== RewardType.VipExp && (
          <LootRewardIcon loot={loot} index={index} responsive={responsive} />
        )}
        {loot.amount > 0 && (
          <AmountText loot={loot} vipLevelCategory={vipLevelCategory} responsive={responsive} />
        )}
        {shine && <LootShine rewardType={loot.rewardType} />}
        {loot.timeInterval && <LootTimeIntervalRibbon timeInterval={loot.timeInterval} />}
      </div>
    </div>
  )
}

export default StandardLootReward
